
import { Component, Vue, Watch } from 'vue-property-decorator'
import { apiShopCoordinateLists } from '@/api/shop'
import AreaSelect from '@/components/area-select.vue'
import MaterialSelect from '@/components/material-select/index.vue'
import { importCDN } from '@/utils/importCDN'
import config from '@/config'

@Component({
    components: {
        AreaSelect,
        MaterialSelect
    }
})
export default class ListsDetail extends Vue {
    /** S Data **/

    // 套餐列表数据
    setMealData: any = []

    form = {
        latitude: '36.03',
        longitude: '103.40'
    }

    // 腾讯地图对象
    tencentMap: any = undefined
    // 腾讯地图锚点对象
    markerLayer: any = undefined

    /** E Data **/

    /** S Methods **/
    // 获取套餐列表
    async getShopCoordinateLists(): Promise<void> {
        const TencentMap = (window as any).TMap
        const list = await apiShopCoordinateLists()

        const stylesArr: any = {}

        list.forEach((item: any, index: number) => {
            const image = item.image == '' ? require('@/assets/images/icon_marker.png') : item.image
            stylesArr['me' + index] = new TencentMap.MarkerStyle({
                width: 34, // 点标记样式宽度（像素）
                height: 34, // 点标记样式高度（像素）
                src: image, //图片路径
                //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
                // anchor: { x: 17, y: 34 }
            })
        })

        this.initTencentMap(stylesArr)

            ; (window as any).handleSearchMap = (data: any) => { }

        list.forEach((item: any, index: number) => {
            this.markerLayer.add({
                styleId: 'me' + index,
                id: item.id,
                position: new TencentMap.LatLng(item.latitude, item.longitude)
            })
        });
    }


    // 初始化腾讯地图
    initTencentMap(stylesArr: any) {
        const TencentMap = (window as any).TMap

        const latLng = {
            lat: this.form.latitude,
            lng: this.form.longitude
        }

        //定义地图中心点坐标
        const center = new TencentMap.LatLng(latLng.lat, latLng.lng)

        //定义map变量，调用 TencentMap.Map() 构造函数创建地图
        this.tencentMap = new TencentMap.Map(document.getElementById('tencent-map'), {
            center: center, //设置地图中心点坐标
            zoom: 5, //设置地图缩放级别
            pitch: 0, //设置俯仰角
            rotation: 0 //设置地图旋转角度
        })

        console.log(stylesArr)

        //创建并初始化MultiMarker
        this.markerLayer = new TencentMap.MultiMarker({

            map: this.tencentMap, //指定地图容器
            minimumClusterSize: 2,  //最小聚合点数：2个
            styles: stylesArr,
            //点标记数据数组
            geometries: [],
            oomOnClick: true,  //点击聚合数字放大展开
            gridSize: 60,       //聚合算法的可聚合距离，即距离小于该值的点会聚合至一起，默认为60，以像素为单位
            averageCenter: false, //每个聚和簇的中心是否应该是聚类中所有标记的平均值
            maxZoom: 16 //采用聚合策略的最大缩放级别，若地图缩放级别大于该值，则不进行聚合，标点将全部被展开
        })

    }
    /** E Methods **/


    /** S Life Cycle **/
    created() {
        const TENTCENT_MAP_API = `https://map.qq.com/api/gljs?v=1.exp&key=${config.tencentMapKey}`
        importCDN(TENTCENT_MAP_API).then(() => this.getShopCoordinateLists())
    }
    /** E Life Cycle **/
}
